import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import Router from './router';
import { store } from './store';
import './i18n';

import Theme from './theme';

const App = () => {
	return (
		<Provider store={store}>
			<Theme>
				<RouterProvider router={Router} />
			</Theme>
		</Provider>
	);
};

export default App;
