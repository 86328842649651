import { configureStore } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { catchError } from 'rxjs/operators';

const epicMiddleware = createEpicMiddleware();

import userPoolsSlice from '../containers/LandingPage/userPoolsSlice';

import userPoolsEpics from '../containers/LandingPage/userPoolsEpics';

const epics = combineEpics(
	...userPoolsEpics
);

const rootEpic = (action$, store$, dependencies) =>
  epics(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );

export const store = configureStore({
  reducer: {
		userPoolsSlice
  },
  middleware: [epicMiddleware],
});

epicMiddleware.run(rootEpic);