import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import OrganizationSelect from './OrganizationSelect';
import LinearStepper from './components/LinearStepper';
import EmailInput from './components/EmailInput';
import { withTranslation } from 'react-i18next';
import { useKeyPress } from './helpers/useKeyPress';
import config from '../../config.json';

import { getUserPools, resetUserPools } from '../../containers/LandingPage/userPoolsSlice';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

const StepperContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: theme.palette.background.default,
	minWidth: '25vw',
	maxWidth: '35vw',
	minHeight: '20vh',
	maxHeight: '50vh',
	overflowY: 'auto',
	overflowX: 'auto',
	border: `2.5px solid ${theme.palette.primary.main}`,
	borderRadius: '25px',
	'&::-webkit-scrollbar': {
		width: '6px',
		height: '6px'
	},
	'&::-webkit-scrollbar-track': {
		boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
		webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.4)',
		margin: '3vh 2vw 3vh 2vw',
		borderRadius: '25px'
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: 'rgba(0,0,0,.1)',
		outline: '.5px solid slategrey',
		borderRadius: '25px'
	}
}));

const StepOrchestration = ({ t }) => {
	const dispatch = useDispatch();

	const [emailInput, setEmailInput] = useState('');
	const [emailError, setEmailError] = useState([1]);
	const [ selectedUserPool, setSelectedUserPool ] = useState();

	const {
		isLoading: userPoolsAreLoading,
		hasBeenLoaded: userPoolsHaveBeenLoaded
	} = useSelector(state => state.userPoolsSlice.userPools);

	const returnPress = useKeyPress('Enter');

	const handleNext = () => {
		dispatch(getUserPools(emailInput));
	};

	const handleBack = () => {
		dispatch(resetUserPools());
	};

	const handleSubmit = () => {
		const cpi = {
			ACID: selectedUserPool.poolAppClientId,
			ORG: selectedUserPool.organization,
			REG: selectedUserPool.poolRegion,
			UNAME: selectedUserPool.username,
			UPID: selectedUserPool.poolId
		};

		const encodedCPI = encodeURIComponent(JSON.stringify(cpi));
		window.location.assign(
			`${
				config[process.env.REACT_APP_STAGE].dashboardPageURI
			}?CPI=${encodedCPI}`
		);
	}

	if (returnPress && emailInput?.length > 0 && emailError?.length < 1) {
		handleNext();
	}

	return (
		<StepperContainer maxWidth={false}>
			<Stack alignItems='center' sx={{ flex: 1 }} pt={2}>
			{ !userPoolsHaveBeenLoaded
				? (
					<EmailInput 
					emailInput={emailInput}
					setEmailInput={setEmailInput}
					emailError={emailError}
					setEmailError={setEmailError}
					/>
				) : null
			}
			{ userPoolsHaveBeenLoaded
				? (
					<OrganizationSelect	handleSelect={setSelectedUserPool} selectedUserPool={selectedUserPool} />
				) : null
			}
			</Stack>
			<Stack direction='row' alignItems='space-between' spacing={2} m={2}>
				<Button
					variant='outlined'
					color='primary'
					type='button'
					sx={{ visibility: userPoolsHaveBeenLoaded ? 'visible': 'hidden' }}
					size='small'
					onClick={handleBack}
					disabled={!userPoolsHaveBeenLoaded}
				>
					{t('pages.landingpage.back')}
				</Button>
				<LinearStepper
					activeStep={userPoolsHaveBeenLoaded ? 1 : 0 }
				/>
				{ userPoolsHaveBeenLoaded
					? (
						<Button
						variant='outlined'
						type='submit'
						disabled={!selectedUserPool}
						color='primary'
						size='small'
						onClick={handleSubmit}
					>
					{t('pages.landingpage.finish')}
				</Button>
					) : null
				}
				{ !userPoolsHaveBeenLoaded
					? (
						<LoadingButton
						variant='outlined'
						type='submit'
						loading={	userPoolsAreLoading}
						disabled={emailError.length !== 0}
						color='primary'
						size='small'
						onClick={handleNext}
					>
					{t('pages.landingpage.next') }
					</LoadingButton>
					) : null
				}
			</Stack>
		</StepperContainer>
	);
};

StepOrchestration.propTypes = {
	error: PropTypes.string,
	getUserTenetList: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	t: PropTypes.func,
	userTenetList: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default withTranslation()(StepOrchestration);