import React from 'react';
import { withTranslation } from 'react-i18next';
import StepOrchestration from '../../components/UserPoolsStepper/StepOrchestration';

import Container from '@mui/material/Container';

const LandingPage = (props) => {

	return (
		<Container maxWidth={false} sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100vh',
			width: '100vw'
		}}>
			<StepOrchestration />
		</Container>
	);
};

export default withTranslation()(LandingPage);
