import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	userPools: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const userPoolsSlice = createSlice({
	name: 'userPools',
	initialState,
	reducers: {
		getUserPools: (state) => {
			state.userPools.isLoading = true;
			state.userPools.error = undefined;
		},
		getUserPoolsCompleted: (state, action) => {
			state.userPools.isLoading = false;
			state.userPools.hasBeenLoaded = true;
			state.userPools.data = action.payload;
		},
		getUserPoolsFailed: (state, action) => {
			state.userPools.isLoading = false;
			state.userPools.error = action.payload;
		},
		resetUserPools: (state) => {
			state.userPools = initialState.userPools;
		}
	}
});

export const {
	getUserPools,
	getUserPoolsCompleted,
	getUserPoolsFailed,
	resetUserPools
} = userPoolsSlice.actions;

export default userPoolsSlice.reducer;
