import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

const LinearStepper = ({ activeStep, t } ) => {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', flex: 1}}>
			<Stepper activeStep={activeStep} sx={{ width: '80%', alignSelf: 'center'}} connector={null}>
				<Step>
					<StepLabel>{t('pages.landingpage.stepper.email')}</StepLabel>
				</Step>
				<Step>
					<StepLabel>{t('pages.landingpage.stepper.organization')}</StepLabel>
				</Step>
			</Stepper>
		</Box>
	);
};

LinearStepper.propTypes = {
	activeStep: PropTypes.number,
	t: PropTypes.func
};

export default withTranslation()(LinearStepper);
