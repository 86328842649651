import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import OrganizationCard from './OrganizationCard';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const OrganizationSelect = ({ t, handleSelect, selectedUserPool }) => {
	const {
		data: userPools
	} = useSelector(state => state.userPoolsSlice.userPools);

	if (userPools?.length === 0) {
		return (
			<Stack spacing={2} justifyContent='center' sx={{ flex: 1 }}>
				<Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 520 }}>
					{t('pages.landingpage.emailNotFoundLine1')}
				</Typography>
				<Typography variant="body1" sx={{ textAlign: 'center' }}>
					{t('pages.landingpage.emailNotFoundLine2')}
				</Typography>
			</Stack>
		)
	}

	return (
		<Box sx={{ margin: '1vh' }}>
			{ userPools?.map((userPool) => (
				<OrganizationCard
					key={userPool?.poolId}
					handleSelect={handleSelect}
					userPool={userPool}
					isSelected={userPool.poolId === selectedUserPool?.poolId}
				/>
			))
			}
		</Box>
	)
};

OrganizationSelect.propTypes = {
	handleSelect: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation()(OrganizationSelect);
