import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import LandingPage from '../pages/LandingPage';

// const AppRouter = (props) => {
// 	return (
// 		<Router>
// 			<Routes>
// 				<Route element={<Layout />}>
// 					<Route exact path='/' element={<LandingPage />} />
// 				</Route>
// 			</Routes>
// 		</Router>
// 	);
// };

const router = createBrowserRouter([
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '',
				element: <LandingPage />
			}
		]
	}
]);

// AppRouter.propTypes = {
// 	t: PropTypes.func
// };

export default router;
