import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config.json';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const OrganizationCard = ({ userPool, handleSelect, isSelected }) => {
	return (
		<Card sx={{
			width: '18vw',
			margin: '1vh',
			border: (theme) => `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.common.white}`
		}}>
			<CardActionArea onClick={() => handleSelect(userPool)}>
				<CardContent>
					<Stack direction='row' spacing={1} alignItems='center'>
						<Stack direction='column' alignItems='center' spacing={1} sx={{ width: 100 }}>
							<Typography 
								variant='subtitle2'
								sx={{
									width: '100px',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									textAlign: 'center'
								}}
							>{userPool?.organization}
							</Typography>
							<Avatar
								alt={userPool?.organization}
								src={`${config[process.env.REACT_APP_STAGE].assetsURI}${
									userPool?.organizationAvatar
								}`}
							>
								{userPool?.organization?.charAt(0)}
							</Avatar>
						</Stack>
						<Box pl={2}>
							<Typography variant='h6'>{userPool?.username}</Typography>
						</Box>
					</Stack>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

OrganizationCard.propTypes = {
	handleSelect: PropTypes.func,
	organizations: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default OrganizationCard;
