import React from 'react';
import { withTranslation } from 'react-i18next';
import Slyce360Icon from '../../Icons/Slyce360Logo';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const Navbar = (props) => {

	return (
		<Container maxWidth={false} sx={{
			display: 'flex',
			justifyContent: 'space-between',
			minHeight: (theme) => theme.panel.height,
			backgroundColor: (theme) => theme.palette.navigation.background,
			padding: '0px',
			margin: '0px',
			dropShadow: '0px 2px 2px #00000',
			zIndex: '9999'
		}}>
			<Box sx={{
				display: 'flex',
				width: (theme) => theme.panel.width,
				borderRight: (theme) => `1px solid ${theme.palette.background.default}`
			}}>
				<Box sx={{
					display: 'flex',
					width: '100%',
					alignContent: 'center',
					marginLeft: '20px',
					'& svg': {
						width: '70%'
					},
					textDecoration: 'none'
				}}>
					<Slyce360Icon />
				</Box>
			</Box>
		</Container>
	);
};

export default withTranslation()(Navbar);
