import axios from 'axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import config from '../../config.json';

import {
	getUserPools,
	getUserPoolsCompleted,
	getUserPoolsFailed,
} from './userPoolsSlice';

export const getUserPoolsEpic = (action$) =>
	action$.pipe(
		ofType(getUserPools),
		mergeMap(async (action) => {
			const setBody = {
				email: action.payload
			};

			const response = await axios.post(
				`${
					config[process.env.REACT_APP_STAGE].api.secondaryGateway
				}/api/userpool`,
				setBody
			);

			return response?.data;
		}),
		switchMap((res) => [getUserPoolsCompleted(res)]),
		catchError((error, source) =>
			merge(of(getUserPoolsFailed(error.message)), source)
		)
	);

export default [
	getUserPoolsEpic
]
