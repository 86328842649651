import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Footer = ({ t }) => {
	return (
		<Container maxWidth={false} sx={{
			backgroundColor: '#fafafa',
			display: 'flex',
			height: (theme) => theme.panel.height,
			width: '100%',
			justifyContent: 'center',
			alignItems: 'center',
			bottom: 0,
			position: 'absolute',
			'&p': {
				textAlign: 'center'
			}
		}}>
			<Typography variant="subtitle2">
				{t('components.layout.footer.copywrite')}
			</Typography>
		</Container>
	);
};

Footer.propTypes = {
	t: PropTypes.func
};

export default withTranslation()(Footer);
